// export const BASE_URL = "http://192.168.1.38/figma/public/api/"; // local ip
// export const BASE_URL =
// "http://192.168.1.38/classified-backend-laraval/public/api/"; // local ip karthishwari akka

export const BASE_URL = "https://backend.parangipettai.com/api";

// export const BASE_URL =
// "https://crm.portonovo.premiumfreshfoods.com/public/api/"; // premium ip

export const URL = {
  // LOGIN
  LOGIN: "login",
  VERIFY_OTP: "otp_verification",
  RESEND_OTP: "resendotp",
  LOG_OUT: "logout",
  REGISTER: "register",
  PROFILE_VIEW: "profileview",
  PROFILE_EDIT: "profileedit",
  BANNER_LIST: "bannerslist",
  CATEGORY_LIST: "categorylist",
  CONTACT_US: "contactus",
  CONTACT_VIEW: "contactview",
  POST_CREATE: "create_post",
  POST_LIST: "postlist",
  SUBCATEGORIES: "subcategories",
  CLASSIFIED_EDIT: "classifiededit",
  IMAGE_DELETE: "imagedelete",
  CATEGORY_DELETE: "categorydelete",
  CATEGORY_VIEW: "categoryview",
  SERVICE_EDIT: "serviceedit",
  JOB_VACANCY_POST: "jobvacancypostcreate",
  OVERALL_LIST: "overall",
  SUBCATEGORY_LIST: "subcategorylist",
  SERVICE_BANNERS: "service_banners",
  HOME_SHOW_CATEGORIES: "homeShowcategories",
  JOB_VACANCY_UPDATE: "jobvacancypostupdate",
  JOB_VACANCY_VIEW: "jobvacancypostview",
  JOB_SUBCATEGORIES: "jobSubCategoriesList",
  EXPLORE_BANNERS: "explore_banners",
  EXPLORE_POST_CREATE: "explorepostcreate",
  EXPLORE_POST_VIEW: "explorepostview",
  EXPLORE_POST_UPDATE: "explorepostupdate",
  SEARCH: "search",
  ENQUIRY: "enquiry",
  CATEGORY_LISTS: "list",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const options = {
  loop: true,
  center: false,
  items: 6,
  autoplay: true,
  dots: false,
  autoplayTimeout: 5000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
      margin: 5,
    },
    576: {
      items: 2,
      margin: 5,
    },
    767: {
      items: 3,
      margin: 10,
    },
    1199: {
      items: 3,
      margin: 10,
    },
    1600: {
      items: 5,
      margin: 10,
    },
  },
};

export const row2 = {
  loop: false,
  autoplay: false,
  center: false,
  dots: false,
  autoplayTimeout: 5000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
      margin: 5,
    },
    576: {
      items: 1,
      margin: 15,
    },
    991: {
      items: 2,
      margin: 15,
    },
    1199: {
      items: 2,
      margin: 20,
    },
    1600: {
      items: 2,
      margin: 15,
    },
  },
};
export const row3 = {
  loop: true,
  autoplay: true,
  center: false,
  dots: false,
  autoplayTimeout: 5000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
      margin: 5,
    },
    576: {
      items: 1,
      margin: 15,
    },
    991: {
      items: 2,
      margin: 15,
    },
    1199: {
      items: 2,
      margin: 20,
    },
    1600: {
      items: 2,
      margin: 15,
    },
  },
};
