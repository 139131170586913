// @ts-nocheck
import React, { useEffect, useState } from "react";
import { curve, profile1 } from "../../../assets/img";
import OwlCarousel from "react-owl-carousel";
import { options } from "../../../redux/api/constants";
import { serviceJson } from "../../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import PlaceIcon from "@mui/icons-material/Place";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCategoryDeleteMutation,
  usePostListMutation,
} from "../../../redux/api/api";
import useUser from "../../../redux/store/userData";
import moment from "moment/moment";
import ConfirmPopup from "../../Popup/ConfirmPopup";

const ServiceLists = ({
  method,
  type,
  serviceList,
  subCategTabList,
  setListOrder,
  listOrder,
}) => {
  const { user } = useUser();
  const [showSort, setShowSort] = useState(false);
  const navigate = useNavigate();

  const [postLists] = usePostListMutation();
  const [deletePost] = useCategoryDeleteMutation();
  const [list, setList] = useState([]);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    setList(serviceList?.service || serviceList?.services);
  }, [serviceList]);

  const togglePopup = () => {
    setDeletePopup(!deletePopup);
  };

  const getList = () => {
    let formdata = new FormData();
    formdata.append("type", 2);
    postLists(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        if (res?.status == "success") {
          res?.postList?.map((item, ind) => {
            item?.classifieds?.map((items, inds) => {
              temp.push(items);
            });
          });
          let reverse = [...res?.postList].reverse();
          setList(reverse);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (type == "home") {
      //
    } else {
      getList();
    }
  }, [type]);

  const showHandler = (order) => {
    if (order === "asc" || order === "desc") {
      setListOrder(order);
    }
    setShowSort(!showSort);
  };

  const deleteApi = () => {
    setDisableDeleteBtn(true);
    // console.log(item);
    const formData = new FormData();
    formData.append("post_id", deleteItem?.id);
    formData.append("type", 2); // {1->classified,2->service}
    deletePost(formData)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          console.log(res);
          // getList();
          window.location.reload();
          setDisableDeleteBtn(false);
          setDeleteItem({});
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableDeleteBtn(false);
        togglePopup();
      });
  };

  const onClickDelete = (item) => {
    setDeleteItem(item);
    togglePopup();
  };

  // console.log("list", list);

  return (
    <div className="tl-service text-center pb-3">
      {deletePopup && (
        <ConfirmPopup
          onCloseClick={togglePopup}
          message={"Are you sure you want delete"}
          disableBtn={disableDeleteBtn}
          onConfirmClick={deleteApi}
        />
      )}
      {method !== "TabMethod" && (
        <div className="d-flex ac-jb mb-2 mx-2">
          <h5 className="result-txt">
            Result -{" "}
            <span>
              {serviceList?.pagination?.total
                ? serviceList?.pagination?.total
                : "No Data Found"}
            </span>
          </h5>
          <div className="sort-by">
            <button onClick={showHandler} className="cust-btn show-text">
              Sort by <KeyboardArrowDownIcon />
            </button>
            {showSort && (
              <div className="download-box">
                <button
                  onClick={() => showHandler("desc")}
                  className="cust-btn f3 py-1"
                  style={
                    listOrder == "desc"
                      ? { background: "#008cff", color: "white" }
                      : { background: "transparent" }
                  }
                >
                  Newest
                </button>
                <button
                  onClick={() => showHandler("asc")}
                  className="cust-btn f3 py-1"
                  style={
                    listOrder == "asc"
                      ? { background: "#008cff", color: "white" }
                      : { background: "transparent" }
                  }
                >
                  Oldest{" "}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="service-box align-items-center d-flex flex-wrap w-100">
        {list?.map((item, ind) => {
          const { img, name, designation, location, postBy, time } = item;
          // console.log(item);
          return (
            <Col
              // xs={6}
              // lg={4}
              xs={6}
              lg={4}
              // xs={12}
              // lg={4}
              // sm={6}
              xxl={method == "TabMethod" ? 4 : 4}
              className="p-2 px-md-2"
              key={item?.id}
            >
              <div
                className={`p-0 pb-3 mb-md-2 ${
                  method == "TabMethod" && "bg-sec rounded rounded-3"
                }`}
              >
                <div className="box position-relative bg-white">
                  <div className="curve">
                    <img src={curve} className="curve-img w-100" />
                  </div>
                  <div className="box-inner">
                    <div className="profile-img">
                      {/* <KeyboardArrowDownIcon /> */}
                      {method == "TabMethod" ? (
                        <img
                          src={
                            user?.image
                              ? user?.image_path
                              : user?.gender == "Male"
                              ? "https://img.freepik.com/free-photo/smiley-handsome-man-posing_23-2148911841.jpg?w=740&t=st=1689942327~exp=1689942927~hmac=bda76672938776892327321166719cf5f4f117e952887c2f31377b8cacaba331"
                              : "https://st3.depositphotos.com/6179956/15450/i/1600/depositphotos_154504514-stock-photo-university-student-girl-looking-happy.jpg"
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            item?.user?.image
                              ? item?.user?.image_path
                              : item?.user?.gender == "Male"
                              ? "https://img.freepik.com/free-photo/smiley-handsome-man-posing_23-2148911841.jpg?w=740&t=st=1689942327~exp=1689942927~hmac=bda76672938776892327321166719cf5f4f117e952887c2f31377b8cacaba331"
                              : "https://st3.depositphotos.com/6179956/15450/i/1600/depositphotos_154504514-stock-photo-university-student-girl-looking-happy.jpg"
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <h4 className="services-listfont text-capitalize">
                      {method == "TabMethod" ? user?.name : item?.user?.name}
                    </h4>
                    <h5>{item?.speciality}</h5>
                    <p>
                      <PlaceIcon className="iconstyle" />
                      {item?.location}
                    </p>
                    <button
                      onClick={() =>
                        navigate("/service/detail", {
                          state: { post_id: item?.id },
                        })
                      }
                      className="cust-btn"
                    >
                      Read More
                    </button>
                    <p>
                      Post By{" "}
                      <span className="text-capitalize">
                        {method == "TabMethod" ? user?.name : item?.user?.name}{" "}
                        {moment(item?.created_at).fromNow()}
                      </span>
                    </p>
                  </div>
                </div>
                {method == "TabMethod" && (
                  <div className="d-flex ac-je">
                    <button
                      className="cust-btn p-1 edit-delet-icon-tablist"
                      onClick={() =>
                        navigate("/editPost", {
                          state: { data: item, type: "services" },
                        })
                      }
                    >
                      <BorderColorIcon className=" text-white" />
                    </button>
                    <button
                      disabled={disableDeleteBtn}
                      onClick={() => onClickDelete(item)}
                      className="cust-btn p-1 edit-delet-icon-tablist"
                    >
                      <DeleteIcon className=" text-white" />
                    </button>
                  </div>
                )}
              </div>
            </Col>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceLists;
