import React from 'react'
import AboutComo from '../../Components/About/AboutComp/AboutComo'
import EducationComo from '../../Components/About/EducationComp/EducationComo'
import CommerceComo from '../../Components/About/CommerceComp/CommerceComo'
import DigitalComp from '../../Components/About/DigitalComp/DigitalComp'
import FurtureComp from '../../Components/About/FutureComp/FurtureComp'

const AboutScreen = () => {
    return (
        <div className="bg-white py-4 w-100">
            <AboutComo />
            <EducationComo />
            <CommerceComo />
            <DigitalComp />
            <FurtureComp />
        </div>
    )
}

export default AboutScreen